<template>
  <div id="app">
    <div>
      <Banner
        :body="body"
        :image="require('@/assets/image/index/banner.svg')"
        :title="title"
        img-type="svg"
        banner-type="index"
        width="100%"
      />
    </div>
    <div class="margin-top">
      <unit
        title="区块链服务"
        title-en="Blockchain Service"
        bg-color="#F5F9FF"
        comp="chain"
      />
    </div>
    <div style="background-color: rgb(26, 134, 255);height:800px">
      <el-row justify="center" type="flex">
        <h2 style="margin-block-start: 1.83em; color: #ffffff;padding-top:60px">安全测评认证</h2>
      </el-row>
      <el-row justify="center" type="flex">
        <Audition class="content-width" />
      </el-row>
    </div>
    <div>
      <unit
        title="合作企业"
        title-en="Cooperative Companies"
        bg-color="#FFF"
        comp="coop"
      />
    </div>
    <div class="margin-top" v-if="false">
      <el-row justify="center" type="flex">
        <h2>资讯专题</h2>
      </el-row>
      <el-row justify="center" type="flex">
        <div class="content-width">
          <el-col :offset="0" :span="8">
            <el-card
              :body-style="{ padding: '0px' }"
              shadow="hover"
              style="margin: 20px"
            >
              <div class="rel">
                <div class="mask">
                  <el-image
                    class="image"
                    fit="cover"
                    v-bind:src="this.config.feed.cover.trophy"
                  />
                  <div style="padding: 14px">
                    <span>区块链成果展示</span>
                  </div>
                </div>
                <div class="card-content">
                  <Feed title="区块链成果展示" />
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :offset="0" :span="8">
            <el-card
              :body-style="{ padding: '0px' }"
              shadow="hover"
              style="margin: 20px"
            >
              <div class="rel">
                <div class="mask">
                  <el-image
                    class="image"
                    fit="cover"
                    v-bind:src="this.config.feed.cover.campaign"
                  />
                  <div style="padding: 14px">
                    <span>活动宣传</span>
                  </div>
                </div>
                <div class="card-content">
                  <Feed title="活动宣传" />
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :offset="0" :span="8">
            <el-card
              :body-style="{ padding: '0px' }"
              shadow="hover"
              style="margin: 20px"
            >
              <div class="rel">
                <div class="mask">
                  <el-image
                    class="image"
                    fit="cover"
                    v-bind:src="this.config.feed.cover.field"
                  />
                  <div style="padding: 14px">
                    <span>行业资讯</span>
                  </div>
                </div>
                <div class="card-content">
                  <Feed title="行业资讯" />
                </div>
              </div>
            </el-card>
          </el-col>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import {
  Audition,
  Feed,
  Unit,
} from '@/components/index/Index.vue';

import Banner from '@/components/common/Banner.vue';

// import { Carousel } from '@/components/common/Index.vue';

export default Vue.extend({
  data() {
    return {
      config: {
        carousel: [
          'https://picsum.photos/1800/1900',
          'https://picsum.photos/1800/1901',
          'https://picsum.photos/1800/1902',
          'https://picsum.photos/1800/1903',
        ],
        feed: {
          cover: {
            trophy: 'https://picsum.photos/500/500',
            campaign: 'https://picsum.photos/501/500',
            field: 'https://picsum.photos/502/500',
          },
        },
      },
      title: '域乎区块链服务平台',
      body: '加速数据价值应用建设，开启真正链上生活',
    };
  },
  components: {
    Feed,
    Audition,
    /* Carousel, */
    Banner,
    Unit,
  },
});
</script>

<style scoped>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.image {
  width: 100%;
  height: 450px;
  display: block;
}

.mask {
  background-color: #ffffff;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity 0.3s;
}

.mask:hover {
  opacity: 0;
}

.card-content {
  position: absolute;
  background-color: #ffffff;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  transition: opacity 0.3s;
}

.card-content:hover {
  opacity: 1;
}

.rel {
  position: relative;
}

.content-width {
  width: 70%;
}

.margin-top {
  margin-top: 40px;
}

.center {
  justify-content: center;
}
</style>
