<template>
  <div class="content">
    <div style="line-height: 50%">
      <p>
        区块链使用去中心化的数据安全技术，可提升数据安全性、降低数据维护成本、推动组
      </p>
      <p>
        织智能化发展，在银行、审计、物联网
        、医疗、公证、版权管理等领域广泛应用。
      </p>
    </div>
    <div class="block">
      <Carousel :images="this.images" height="300px" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import { Carousel } from '@/components/common/Index.vue';

export default Vue.extend({
  data() {
    return {
      content:
        '区块链使用去中心化的数据安全技术，可提升数据安全性、降低数据维护成本、推动组织智能化发展，在银行、审计、物联网 、医疗、公证、版权管理等领域广泛应用。',
      images: [
        'https://picsum.photos/1500/500',
        'https://picsum.photos/1501/500',
        'https://picsum.photos/1502/500',
        'https://picsum.photos/1503/500',
      ],
    };
  },
  components: {
    Carousel,
  },
});
</script>

<style scoped>
.content {
  text-align: center;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
