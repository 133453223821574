<template>
  <div class="banner">
    <el-image class="banner" fit="cover" :src="imgSrc" />
    <span class="text-top">新用户开户攻略</span>
    <span class="text">
      注册简单 极速查询
    </span>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['title', 'body'],
  data() {
    return {
      imgSrc: require('@/assets/image/index/banner.png'),
    };
  },
};
</script>

<style scoped>
.banner {
  overflow: hidden;
  width: 100%;
  height: 550px;
  position: relative;
  text-align: left;
}
.image {
  width: 100%;
  height: 550px;
  object-fit: cover;
}
.text-top {
  position: absolute;
  top: 200px;
  left: 12vw;
  width: 500px;
  min-width: 400px;
  color: #ffffff;
  font-size: 50px;
  text-shadow: 0 0 20px rgba(0,0,0,0.2);
}
.text {
  position: absolute;
  top: 300px;
  left: 12vw;
  width: 34%;
  max-width: 643px;
  min-width: 360px;
  color: #ffffff;
  font-size: 28px;
  text-shadow: 0 0 10px rgba(0,0,0,0.4);
}
</style>
