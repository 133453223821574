<template>
  <div class="unit" :style="backColor">
    <div style="width: 80%">
      <div class="title">{{ title }}</div>
      <div class="title-en">{{ titleEn }}</div>
      <div class="content">
        <div v-if="comp === 'chain'">
          <Chain />
        </div>
        <div v-else-if="comp === 'coop'">
          <Coops />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chain from './content/Chain.vue';
import Coops from './content/Coops.vue';

export default {
  props: ['bgColor', 'title', 'titleEn', 'comp'],
  computed: {
    backColor() {
      return {
        background: this.bgColor,
      };
    },
  },
  components: {
    Chain,
    Coops,
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100&display=swap');

.unit {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 120px;
  margin-bottom: -50px;
  margin-top: -50px;
}

.title {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 40px;
  margin-left: 70px;
}

.title-en {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 24px;
  opacity: 0.5;
  margin-left: 70px;
}

.content {
  padding-top: 20px;
}

</style>
