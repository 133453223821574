<template>
  <div>
    <el-card shadow="never" style="width:100%">
      <el-tabs
        v-model="defaultTab"
        :stretch="true"
      >
        <el-tab-pane
          v-for="(tab, index) in tabs"
          :key="tab.name"
          :label="tab.label"
          :name="tab.name"
          >I am {{ tab.label }} in {{ index }}
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      defaultTab: 'basicSrv',
      tabs: [
        {
          name: 'basicSrv',
          label: '基础服务',
        },
        {
          name: 'capSrv',
          label: '能力服务',
        },
        {
          name: 'alliSrv',
          label: '联盟链服',
        },
        {
          name: 'cloudSrv',
          label: '云服务',
        },
        {
          name: 'caSrv',
          label: 'CA服务',
        },
      ],
    };
  },
});
</script>

<style scoped>
.el-tabs__item {
  font-size: 18px;
}

.el-tabs__item.is-active {
  text-shadow: 0px 0px 4px #bbbbbb;
  color: #409eff;
}
</style>
