<script>
import Feed from '@/components/feed/Widget.vue';
import ChainService from '@/components/chain/Widget.vue';
import Companies from '@/components/about/Widget.vue';
import Audition from '@/components/audition/Widget.vue';

import Banner from './Banner.vue';
import Unit from './Unit.vue';

export {
  Feed,
  ChainService,
  Companies,
  Audition,
  Banner,
  Unit,
};

export default {};
</script>
