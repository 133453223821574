<template>
  <div id="card">
    <p>{{ title }}</p>
    <el-divider></el-divider>
    <el-row
      :gutter="20"
      type="flex"
      align="middle"
      v-for="content in contents"
      :key="content.id"
      class="item"
    >
      <el-col :span="8" :offset="0">
        <img
          src="@/assets/image/feed/placeholder.jpg"
          width="100%"
          height="100%"
          style="display: block"
        />
      </el-col>
      <el-col :span="16" :offset="0">
        <el-row type="flex">{{ content.date }}</el-row>
        <el-row type="flex">{{ content.description }}</el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20" justify="end" style="margin-top:15px;">
      <el-link>查看更多>></el-link>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: ['title'],
  data() {
    return {
      contents: [
        {
          id: 0,
          date: '2020.1.11',
          description: 'This is test article A',
        },
        {
          id: 1,
          date: '2020.1.12',
          description: 'This is test article N',
        },
        {
          id: 2,
          date: '2020.1.13',
          description: 'This is test article D',
        },
        {
          id: 3,
          date: '2020.1.14',
          description: 'This is Joe Mama',
        },
      ],
    };
  },
});
</script>

<style scoped>
#card {
  padding: 10px;
}

.item {
  margin-bottom: 10px;
}
</style>
