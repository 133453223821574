<template>
  <div>
    <div class="switches">
      <span v-for="(item, index) in data" :key="index">
        <el-button
          plain
          size="medium"
          @click="clicked(index)"
          :style="[currentSelect === index ? selectedButton : unselectButton]"
          ref="button"
          >{{ item.label }}</el-button
        >
      </span>
    </div>
    <div class="carousel">
      <el-carousel
        type="default"
        direction="horizontal"
        :initial-index="carouselInitialIndex"
        :autoplay="false"
        indicator-position="none"
        arrow="never"
        height="500px"
        style="padding-left: 80px; padding-right: 80px; padding-top: 20px"
        ref="carousel"
      >
        <el-carousel-item
          v-for="(item, index) in data"
          :key="index"
          class="carousel-item"
        >
          <el-image :src="item.img" fit="cover" style="max-width: 900px" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chain',
  data() {
    return {
      carouselInitialIndex: 0,
      data: [{
        label: '基础服务',
        img: require('@/assets/image/index/blockchain-service/1.jpg'),
      }, {
        label: 'BaaS服务',
        img: require('@/assets/image/index/blockchain-service/2.jpg'),
      }, {
        label: '增值服务',
        img: require('@/assets/image/index/blockchain-service/3.jpg'),
      }, {
        label: '行业链服',
        img: require('@/assets/image/index/blockchain-service/4.jpg'),
      }],
      focus: 1,
      unselectButton: {
        margin: '5px',
        width: '110px',
        border: 'solid 1px #2285ff',
        color: '#2285ff',
      },
      selectedButton: {
        margin: '5px',
        width: '110px',
        background: '#2285ff',
        border: 'solid 1px #2285ff',
        color: 'white',
      },
      currentSelect: 0,
    };
  },
  mounted() {
    this.$refs.button[0].$el.focus();
  },
  methods: {
    clicked(index) {
      this.$refs.carousel.setActiveItem(index);
      this.currentSelect = index;
    },
  },
};
</script>

<style scoped>
.switches {
  padding-bottom: 20px;
  padding-left: 70px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.image {
  width: 100%;
  height: 400px;
  margin-top: 100px;
  object-fit: scale-down;
}
</style>
