<template>
  <div class="allImage">
    <div>
      <p class="white">{{ summary }}</p>
    </div>
    <div>
      <el-col style="margin-block-end: 80px">
        <el-row
          :gutter="20"
          v-for="item in list"
          :key="item.id"
          type="flexbox"
          align="middle"
          justify="center"
        >
          <div class="center white-bg margin">
            <el-col :span="3" :offset="0" style="min-width: 110px">
              <img v-bind:src="item.logo" />
            </el-col>
            <el-col :span="21" :offset="0" class="box-text">
              <p class="text-title">{{ item.name }}</p>
              <p class="text">{{ item.description }}</p>
            </el-col>
          </div>
        </el-row>
      </el-col>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      summary:
        '区块链企业测评平台和监管平台，联合工信部电标院、信通院、赛迪研究院等权威机构为区块链企业提供功能、性能、安全性、智能合约代码审计等的检测与评估。同时根据政府需要，可为政府及执法部门提供区块链企业监管，提升区块链技术及应用的合规性和规范性，建立健全区块链监管体系。',
      list: [
        {
          id: 0,
          name: '电标院',
          description:
            '电子标准院以电子信息技术标准化工作为核心，通过开展标准科研、检测、计量、认证、信息服务等业务，面向政府提供政策研究、行业管理和战略决策的专业支撑，面向社会提供标准化技术服务。',
          logo: require('@/assets/image/audition/ESI.png'),
        },
        {
          id: 1,
          name: '信通院',
          description:
            '中国信息通信研究院现为工业和信息化部直属科研事业单位，在发展战略、自主创新、产业政策、行业管理、规划设计、技术标准、测试认证等方面颇有建树，在政府和行业决策、创新与发展中起到关键支撑和推动作用，在国际国内信息通信行业具有很高声誉和影响力。',
          logo: require('@/assets/image/audition/CAICT.png'),
        },
        {
          id: 2,
          name: '赛迪研究院',
          description:
            '中国电子信息产业发展研究院主要从事产业政策、经济形势等软科学研究，媒体出版，顾问咨询，评测认证等业务，形成了“传媒与网络服务，咨询与外包服务，评测与认证服务，软件开发与技术服务”四业并举的业务格局。',
          logo: require('@/assets/image/audition/CCID.png'),
        },
      ],
    };
  },
});
</script>

<style scoped>
.white-bg {
  background: #ffffff;
}

.margin {
  margin: 10px;
}

.white {
  color: #ffffff;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-text {
  min-height: 110px;
  text-align: left;
}

.text {
  margin-block-start: 0.5em;
  font-size: 14px;
  font-family: PingFang SC Regular;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-title {
  margin-block-start: 0.5em;
  margin-block-end: 0.2em;
  font-weight: bold;
  font-size: 23px;
  font-family: PingFang SC Bold;
}
</style>
