<template>
  <div id="coop">
    <div class="carousel">
      <el-carousel
        type="card"
        indicator-position="none"
        height="228px"
        style="width: 80%;"
      >
        <el-carousel-item v-for="item in businessiconlist" :key="item.index">
          <div>
            <div class="carousel">
              <img :src="item" class="logo"/>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content:
        '区块链使用去中心化的数据安全技术，可提升数据安全性、降低数据维护成本、推动组织智能化发展，在银行、审计、物联网 、医疗、公证、版权管理等领域广泛应用。',
      images: [
        'https://picsum.photos/1500/500',
        'https://picsum.photos/1501/500',
        'https://picsum.photos/1502/500',
        'https://picsum.photos/1503/500',
      ],
      businessiconlist: [

        require('@/assets/image/about/friends/06.png'),
        require('@/assets/image/about/friends/11.png'),
        require('@/assets/image/about/friends/16.png'),
        require('@/assets/image/about/friends/21.png'),
        require('@/assets/image/about/friends/07.png'),
        require('@/assets/image/about/friends/12.png'),
        require('@/assets/image/about/friends/17.png'),
        require('@/assets/image/about/friends/22.png'),
        require('@/assets/image/about/friends/08.png'),
        require('@/assets/image/about/friends/13.png'),
        require('@/assets/image/about/friends/18.png'),
        require('@/assets/image/about/friends/19.png'),
        require('@/assets/image/about/friends/24.png'),
        require('@/assets/image/about/friends/10.png'),
        require('@/assets/image/about/friends/01.jpg'),
        require('@/assets/image/about/friends/chinamobile.jpg'),
        require('@/assets/image/about/friends/tencentcloud.jpg'),
        require('@/assets/image/about/friends/bsnlogo.png'),
      ],
    };
  },
  components: {
  },
};
</script>

<style scoped>
#coop {
  text-align: center;
}

.desc {
  color: grey;
  padding-bottom: 30px;
}

.carousel {
  padding-top: 20px;
  display:flex;
  justify-content: center;
  align-items: center;
}

.logo {
  background-color: white;
  width: 100%;
  height: 200px;
  opacity: 1;
  object-fit: scale-down;
  border: solid 1px rgba(0,0,0,0.2);
}
</style>
